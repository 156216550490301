


























import Vue from 'vue';
import { mapGetters } from 'vuex';
import { bytesToString } from '@/util/bytes';
import Axios from 'axios';
import { Download } from '@/types/state';
import FileDownload from '@/components/media/Download.vue';

export default Vue.extend({
  components: {
    FileDownload,
  },
  computed: {
    ...mapGetters({
      downloads: 'download/list',
    }),
    list(): Download[] {
      return this.downloads;
    },
  },
  methods: {
    bytesToString,
    async mock() {
      const total = Math.random() * 1000;

      this.$store.commit('download/set', {
        id: await this.$store.dispatch('download/getNextId'),
        file: {
          name: 'my-file-name.txt',
        },
        promise: new Promise((r) => r(null)),
        total,
        loaded: Math.min(total, Math.random() * 1000),
        cancel: (message?: string) => { console.log(message); },
        cancelToken: Axios.CancelToken.source(),
        routerTo: {
          name: 'downloads',
        },
      });
    },
  },
});
