var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MediaComponent',[_c('BaseLevel',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('h2',{staticClass:"text-xl ",class:{
            'font-bold': ("#" + (_vm.download.id)) === _vm.$route.hash,
            'font-medium': ("#" + (_vm.download.id)) !== _vm.$route.hash,
          }},[(_vm.download.routerTo)?_c('RouterLink',{attrs:{"to":_vm.download.routerTo},domProps:{"textContent":_vm._s(_vm.download.name)}}):_c('span',{domProps:{"textContent":_vm._s(_vm.download.name)}})],1)])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[(!_vm.download.done)?_c('span',{domProps:{"textContent":_vm._s(((_vm.bytesToString(_vm.download.loaded)) + "/"))}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.bytesToString(_vm.download.total))}})]),_c('div',{staticClass:"level-item"},[(!_vm.download.done && !_vm.download.error)?_c('a',{staticClass:"delete is-small",attrs:{"role":"button","title":_vm.$t('common.cancel')},on:{"click":function($event){_vm.download.cancel(_vm.$t('cancelled'))}}}):(_vm.download.cancelled)?_c('span',{staticClass:"has-text-danger",domProps:{"textContent":_vm._s(_vm.$t('cancelled'))}}):(_vm.download.error)?_c('span',{staticClass:"has-text-danger",domProps:{"textContent":_vm._s(_vm.download.error.message || _vm.$t('error'))}}):_vm._e()]),(!_vm.download.done && !_vm.download.cancelled)?_c('div',{staticClass:"level-item"},[_c('BaseProgress',{staticClass:"min-w-2xs",class:{
            'is-link': !_vm.download.cancelled && !_vm.download.done && !_vm.download.error,
            'is-primary': _vm.download.done,
            'is-danger': _vm.download.error || _vm.download.cancelled,
          },attrs:{"value":_vm.download.loaded,"max":_vm.download.total}})],1):(_vm.download.done)?_c('div',{staticClass:"level-item buttons"},[_c('a',{staticClass:"button font-semibold is-link",attrs:{"href":_vm.download.blobUrl || ("#" + (_vm.download.id))},domProps:{"textContent":_vm._s(_vm.$t('open'))},on:{"click":function($event){$event.preventDefault();return _vm.open($event)}}}),_c('a',{staticClass:"button font-semibold is-primary",attrs:{"href":_vm.download.blobUrl || ("#" + (_vm.download.id))},domProps:{"textContent":_vm._s(_vm.$t('save'))},on:{"click":function($event){$event.preventDefault();return _vm.save($event)}}})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }