





























































































import Vue from 'vue';
import { Download } from '@/types/state';
import { bytesToString } from '@/util/bytes';

export default Vue.extend({
  props: {
    download: {
      required: true,
      type: Object as () => Download,
    },
  },
  methods: {
    bytesToString,
    async open(): Promise<void> {
      window.open(await this.download.getBlobUrl(), '_blank');
    },
    async save(): Promise<void> {
      const a = document.createElement('a');
      a.href = await this.download.getBlobUrl();
      a.download = this.download.name;
      a.click();
    },
    // ! https://caniuse.com/native-filesystem-api
    // async save(): Promise<void> {
    //   const blob = await this.download.promise;
    //   const fileHandle = window.showSaveFilePicker();
    //   const writableStream = await fileHandle.createWritable();
    //   await writableStream.write(blob);
    //   await writableStream.close();
    // },
  },
});
