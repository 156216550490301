import { render, staticRenderFns } from "./Download.vue?vue&type=template&id=743d72e6&scoped=true&"
import script from "./Download.vue?vue&type=script&lang=ts&"
export * from "./Download.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743d72e6",
  null
  
)

/* custom blocks */
import block0 from "./Download.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=en"
if (typeof block0 === 'function') block0(component)

export default component.exports